import React from "react";

 
 
function Experiencetext() {

  return (

    <>


    <div className="col-md-4 order-1 order-md-2">
          <div className="year_offexp">
              <span>10</span>
           </div>
           <h3 className="text-center">Years of <strong>Experiance</strong></h3>
      </div>

      <div className="col-md-8 order-2 order-md-1">
          <h2>Hi, I'm <b>Sreejit Choudhury</b></h2>
          <p>I'm a designer & developer with a passion for web design. I enjoy developing simple, clean and slick websites that provide real value to the end user. Thousands of clients have procured exceptional results while working with me. 
            Delivering work within time which meets client’s requirements is my moto.</p>

            <div className="row add_list">
                <div className="col-md-4">
                      <h4>Name:</h4>
                      <h5>Sreejit Choudhury</h5>
                </div>
                <div className="col-md-3">
                      <h4>Date of birth:</h4>
                      <h5>23-09-1988</h5>
                </div>
                <div className="col-md-5">
                      <h4>From:</h4>
                      <h5>Askoknagar | North 24 Parganas</h5>
                </div>
            </div>
      </div>

      
      <div className="col-lg-12 order-2 order-md-3 mt-3">
        <ul>
        <li>
            <div className="exp_list">
              <h3>Senior HTML Developer</h3>
              <h4>CodeClouds    | Kolkata, West Bengal, India</h4>
              <h5>Mar 2022 - Present · 1 yr 8 mos</h5>
              <p>Roll : HTML5, CSS3, PSD & Figma to HTML, SCSS, Tailwind CSS, React js, PSD to WordPress Integration, Bitbucket. Customize WordPress theme, Photoshop, Bootstrap or other framework</p>
            </div>
          </li>
        <li>
            <div className="exp_list">
              <h3>Senior Web Designer/HTML Developer</h3>
              <h4>AmbertimesWeb  | Kolkata, West Bengal, India</h4>
              <h5>May 2016 - Sep 2019  · 3 yr 6 mos</h5>
              <p>Roll : Create Template Mock-up, HTML5, CSS3, PSD to HTML, Create Normal/Responsive HTML Pages, PSD to WordPress Integration etc</p>
            </div>
          </li>
        <li>
            <div className="exp_list">
              <h3>Web Designer</h3>
              <h4>Ascimatics Labs  | Kolkata , Sector V, Saltlake.</h4>
              <h5>May 2016 - Sep 2019  · 3 yr 6 mos</h5>
              <p>Roll : HTML5, CSS3, PSD to HTML, Create Normal/Responsive HTML Pages, WordPress Integration etc</p>
            </div>
          </li>
          <li>
            <div className="exp_list">
              <h3>Web Designer</h3>
              <h4>Simayaa Technologies | JDS HOUSE, Plot No - D2/1, Block - EP, Sector V, Saltlake.</h4>
              <h5>Sep 2015 - May 2016 · 9 mos</h5>
              <p>Roll : HTML5, CSS3, PSD to HTML, Create Normal/Responsive HTML Pages, WordPress Integration etc</p>
            </div>
          </li>
          <li>
            <div className="exp_list">
              <h3>Web Designer</h3>
              <h4>ARC Infotech | Salt Lake Sector III Kolkata</h4>
              <h5>Mar 2014 - Sep 2015 · 1 yr 7 mos</h5>
              <p>Roll : Create Template Mock-up, PSD to HTML, Create Normal/Responsive HTML Pages, HTML5, CSS3, Wordpress integration, Corporate Design,Logos etc</p>
            </div>
          </li>
          <li>
            <div className="exp_list">
              <h3>Back Office Executive</h3>
              <h4>Shaant Infosystems Pvt. Ltd | 14, Mahanirban Road, Kolkata</h4>
              <h5>Oct 2009 - Sep 2013 · 4 yrs</h5>
              <p>Roll : Articles Submission, Blogs Submission, Bookmarks Submission, Classifies Submission, Press Releases Submission, Transitions, Music Uploading, etc.</p>
            </div>
          </li>
        </ul>
      </div>
      
                 

    </>

  );

}


export default Experiencetext;