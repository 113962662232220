import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'; 

import data from '../data/Aboutlistdata.json';
import Abouttext from '../data/HomeComponent/Abouttext';
import Experiencetext from '../data/HomeComponent/Experiencetext';

import Footer from '../pages/partials/Footer';

function About() {

  const posts = data.posts;

  return (
     

    <>

<div className='experiencetext'>
        <Container>
          <Row className='align-items-center'>
              <Experiencetext />
          </Row>
        </Container>
      </div>
        <div className='aboutsections'  >
      <Container>
        <Row className='align-items-center'>
              <div className='col-lg-12'>

                  <Abouttext />
                 
              </div>
        </Row>

        <Row className='align-items-center'>
              <div className='row abourlist mt-0 mt-md-2'>
                 
                  {posts.map((post) => (
                        <div className='col-lg-4 post mt-md-5  mt-4' key={post.id}>

                        
                          <h2 className='aboutlisth2 '>{post.title}</h2> 
                          <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                             
                        </div>
                    ))}
 
              </div>
        </Row>


      </Container>
      </div>


      <div className='contactus'>
        <Container>
          <Row className='align-items-center'>
              <Footer />
          </Row>
        </Container>
      </div>


     
    </>


  );
}

export default About;
