
import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

export class Blog extends Component {
    state = {
        books: [],
        isLoaded: false
    } 
    componentDidMount() {
        axios.get('https://www.sreejit.co.in/article/wp-json/wp/v2/news/')
            .then(res => this.setState({
                books: res.data,
                isLoaded: true
            }))

    }
    render() {

        const { books } = this.state;
        const MAX_LENGTH = 150;
        return (

            <div className="">



                {books.slice(0, 3).map((Postlist) => (
                    <div className='blog_list' key={Postlist.title.rendered}>

                      
                        <h2 dangerouslySetInnerHTML={{ __html: Postlist.title.rendered }}></h2>
                        <p dangerouslySetInnerHTML={{ __html: Postlist.content.rendered.substring(0, MAX_LENGTH) }}></p>
                         

                        <Link className="btn btn-info" to={`/${Postlist.id}`}> View More <i className="fa-solid fa-arrow-right"></i> </Link>

                        <br></br><br></br>




                    </div>
                ))
                } 
 

            </div>




        )




    }
}
export default Blog;