import React, { } from 'react';
 

 

function Bannertext() {
  
    
  return (

    <>  
               
                 
                  <p>Hello there...</p>
                  <h1>Sreejit Choudhury</h1>
                  <h3>I Am A Front End  Developer</h3>
                  <p>Learning front end technologies is a lot more challenging than it used to be.</p>
                   
                  
    </>

  );

}
 

export default Bannertext;