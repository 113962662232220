import React, {  useRef   } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'; 

import data from '../data/Aboutlistdata.json';
import Abouttext from '../data/HomeComponent/Abouttext';
import Bannertext from '../data/HomeComponent/Bannertext';
import Experiencetext from '../data/HomeComponent/Experiencetext';
import Info from '../data/HomeComponent/Info';
import Blog from '../data/HomeComponent/Blog';
import Footer from '../pages/partials/Footer';

import Button from 'react-bootstrap/Button'; 
import {Link } from 'react-router-dom';

 


function Home() {
   
    const posts = data.posts;

    const ref = useRef(null);

    const handleClick = () => {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    

  return (
      
    <>
          

      <div className='bannersections'>
        <Container>
          <Row className='align-items-center'>
              <div className='bannercontent col-lg-6'>
                  <Bannertext />
               <Button onClick={handleClick}  className="btn btn-info banner_btn_round" >About Me</Button>
               <Link to='#about' className="btn btn-info ms-1 ms-lg-3 banner_btn">get in touch</Link>
               </div>
               <img src="../images/sreejit-choudhury.png" alt="" className="bannerimg" />
          </Row>
        </Container>
      </div>

      <div className='aboutsections' id='about' ref={ref}  >
      <Container>
        <Row className='align-items-center'>
              <div className='col-lg-12'>

                  <Abouttext />
                 
              </div>
        </Row>

        <Row className='align-items-center'>
              <div className='row abourlist mt-0 mt-md-2'>
                 
                  {posts.map((post) => (
                        <div className='col-lg-4 post mt-md-5  mt-4' key={post.id}>

                        
                          <h2 className='aboutlisth2 '>{post.title}</h2> 
                          <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
                             
                        </div>
                    ))}
 
              </div>
        </Row>


      </Container>
      </div>


      <div className='experiencetext'>
        <Container>
          <Row className='align-items-center'>
              <Experiencetext />
          </Row>
        </Container>
      </div>


      <div className='info_sections'>
        <Container>
          <Row className='justify-content-end align-items-center'>

            
            <div className="col-md-7">
                    <Info/> 

                    <Button onClick={handleClick}  className="btn btn-info banner_btn_round" >About Me</Button>
                    <Link to='#about' className="btn btn-info ms-1 ms-lg-3 banner_btn">get in touch</Link>
              </div>      
          </Row>
        </Container>
      </div>


     

      <div className='blog_sections'>
        <Container>
          <Row>
            <div className='col-lg-4'>
              <h2>My Blog &<br/>  News</h2> 
              <Link to='/blogs' className="btn btn-info  banner_btn mt-4">View Blogs</Link>
             </div>
            <div className='col-lg-8 mt-4 mt-lg-0'>
              <Blog/> 
             </div>
          </Row>
        </Container>
      </div>

     


      <div className='contactus'>
        <Container>
          <Row className='align-items-center'>
              <Footer />
          </Row>
        </Container>
      </div>

  </>


  );
}
 
export default Home
