import React from "react";
 
function Abouttext() {

  return (

    <>

                <h2 className='aboutsectionsh2'>About Me</h2>
                <p>A technology savvy professional with an exceptional capacity to analyze, solve problems and multi-task. 
                  <span>Technical expertise in highly scalable </span>distributed systems, self-healing systems, and service-oriented architecture

</p>

    </>

  );

}


export default Abouttext;