 
import './App.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
 


import Home from './pages/Home.js';
import About from './pages/About.js';
import Blogpage from './pages/Blogpage.js';
import Blogdetails from './data/HomeComponent/Blogdetails.js';
 
 
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="class global">

 

<Router>
 


<div className='topheader'>
  <Container>
  <Row className='align-items-center'>
        <div className='col-lg-12'>
          <ul>
            <li><Link to='/' className="nav-link "><img src="../images/logo.png" alt="" className="logorimg" width={38} /></Link></li>
            <li><Link to='/' className="nav-link ">Home</Link></li>
            <li><Link to='/about-us' className="nav-link ">About us</Link></li>
            <li><Link to='/blogs' className="nav-link ">Blogs</Link></li>
          </ul>
        </div>
  </Row>
  </Container>
</div>


 
    
<Routes>

   <Route exact path='/' element={<Home />} />
   <Route exact path='/about-us' element={<About />} />
   <Route exact path='/blogs' element={<Blogpage />} />
  
   <Route exact path="/:slug" element={<Blogdetails />} />
  
    
 </Routes>
 
 </Router>
      
 
 

    </div>




 
  );
}

export default App;
