import React from "react";

import {Link } from 'react-router-dom';
 
function Footer() {

  return (

    <>


      <div className="">
        <h2>Let’s Talk? </h2>
        <ul>
       
            <li><Link to='/' className="nav-link ">Home</Link></li>
            <li><Link to='/about-us' className="nav-link ">About us</Link></li>
          </ul>
          <p>Copyright @sreejit.co.in - {(new Date().getFullYear())} </p>
      </div>
                 

    </>

  );

}


export default Footer;