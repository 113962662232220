import React from "react";
 
function Info() {

  return (

    <>

      
        <h2>I Am A Front End Developer</h2>
        <h3>I Can Develop Anything <br/>   You Want</h3>
        <p>A technology savvy professional with an exceptional capacity to analyze, solve problems and multi-task. 
          Technical expertise in highly scalable distributed systems, self-healing systems, and service-oriented architecture</p>
               
                 

    </>

  );

}


export default Info;