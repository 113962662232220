import React  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'; 

import Blog from '../data/HomeComponent/Blog';
import Footer from '../pages/partials/Footer';

 

 


function Home() {
    
  return (
      
    <>
        

      <div className='blog_sections'>
        <Container>
          <Row className='justify-content-center'>
             
            <div className='col-lg-8 mt-4 mt-lg-0'>
              <Blog/> 
             </div>
          </Row>
        </Container>
      </div>

     


      <div className='contactus'>
        <Container>
          <Row className='align-items-center'>
              <Footer />
          </Row>
        </Container>
      </div>

  </>


  );
}
 
export default Home
