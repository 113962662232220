import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'; 
import Footer from '../../pages/partials/Footer';

const Blogdetails = () => {
    const { slug } = useParams();
    const [post, setPost] = useState({});
 
    useEffect(() => {
        // axios
        let url = `https://www.sreejit.co.in/article/wp-json/wp/v2/news/${slug}`;
        axios.get(url).then(res => {
            console.log('res', res);
            setPost(res.data);
        }).catch(err => {
            console.log('error:', err.message);
        });
    }, [slug]);
    return (
        <>
            {
                Object.keys(post).length ? (
                    <div className='container mt-5'>
                        <div className='justify-content-center  row'>
                        <div className='col-lg-9'>
                        <div>
                            <h1 className='text-2xl font-bold'>
                                {post.title.rendered}
                            </h1>
                             

                            <img src={post.featured_src} alt='' className='mb-3 mt-3' />
 

                        </div>
                        <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>

                        <Link to='/blogs' className="btn btn-info  banner_btn mt-4">Back to home</Link>

                    </div>
                    </div>
                    </div>
                ) : (<div className='loading'><div className='loadingmid'>
                    <div className='loadingcenter'> 
                    <img src="../images/loader.gif" alt=""    />  </div></div></div>)

            }


    <div className='contactus mt-4 '>
        <Container>
          <Row className='align-items-center'>
              <Footer />
          </Row>
        </Container>
      </div>
        </>
    )
}
export default Blogdetails;